@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.boot-camp-participant {
  padding: spacing.$s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  list-style: none;

  &:not(:first-child) {
    border-top: 1px solid light.$ge-divider-default;
  }

  &__info {
    color: light.$on-surface-primary-alternate;
    display: flex;
    align-items: center;
    gap: spacing.$xxs;
  }
}
